app.controller('MainCtrl', ['$scope', '$http', 'Lense', 'redditData',
  function($scope, $http, Lense, redditData){

  $scope.videos = [];
  $scope.lense = Lense;
  $scope.loading = true;
  

  redditData.getHotData().then(function(data){
    $scope.videos = data;
    $scope.loading = false;

    $scope.title = data[Lense.val()].data.title;
    $scope.user = data[Lense.val()].data.author;
    $scope.permalink = data[Lense.val()].data.permalink;
    $scope.score = data[Lense.val()].data.score;
    $scope.videoEmbed = data[Lense.val()].data.media_embed.content;
    $scope.timePosted = data[Lense.val()].data.created_utc + '000';

    var postId = data[Lense.val()].data.id;
    redditData.getComments(postId).then(function(comments){
      $scope.comments = comments;
    })

    $scope.updateVideo = function(id){
      postId = data[id].data.id;
      redditData.getComments(postId).then(function(comments){
        $scope.comments = comments;
      })
      $scope.title = data[id].data.title;
      $scope.user = data[id].data.author;
      $scope.permalink = data[id].data.permalink;
      $scope.score = data[id].data.score;
      $scope.videoEmbed = data[id].data.media_embed.content;
      $scope.timePosted = data[id].data.created_utc + '000';
    }
  });
}]);
app.filter('renderHTML', ['$sce', function($sce){
  return function(str){
    str = str.replace('&lt;', '<').replace('&gt;', '>');
    return $sce.trustAsHtml(str);
  }
}]);

app.filter('truncate', function(){
  return function(str){
    if (str.length >= 40) {
      var strArr = str.substring(0,40);
      return String(strArr) + '...';
    } else {
      return str;
    }
  }
});
app.factory('Lense',[function() {
  var lense = {};
  lense.index = 0;
  lense.next = function() {
    lense.index += 1;
  };
  lense.prev = function() {
    lense.index -= 1;
  };
  lense.val = function() {
    return lense.index;
  };
  lense.set = function(i){
    lense.index = i;
  };
  return lense;
}]);

app.factory('redditData', ['$http', '$q', function($http, $q){
  function getHotData(){
    var deferred= $q.defer();
    $http.get('https://www.reddit.com/r/videos/hot.json?limit=100&after=')
      .success(function(data){
        var children = data.data.children;
        for(var i = 0; i < children.length; i++){
          if (children[i].data.stickied || children[i].data.domain === 'twitter.com' || children[i].data.over_18) {
            children.splice(i,1);
            i--; // offset back to before the splice
          }
        }
        deferred.resolve(children);
      });
    return deferred.promise;
  }

  function getCurrentVideoComments(id){
    var deferred = $q.defer();
    $http.get('https://www.reddit.com/r/videos/comments/' + id + '/.json')
      .success(function(data){
        deferred.resolve(data);
      })
      .error(function(err){
        deferred.reject(err);
      });
    return deferred.promise;
  }

  return {
    getHotData: getHotData,
    getComments: getCurrentVideoComments
  };
}]);